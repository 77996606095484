.header {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-size: 1rem;
  color: white;
  min-width: 376px;
  .headerLine {
    height: 4px;
    background-color: #ffffff; // Replace with your brand's primary color
    margin: -20px -20px 20px -20px;
  }
  .title {
    text-align: center;
    font-size: 4rem;
    margin-top: -3rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  .headerText {
    margin-top: 30px;
  }
  .image {
    img {
      width: 100%;
      max-width: 500px;
    }
  }
  .headerContent {
    align-items: center;
  }
  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logoBackground {
    padding: 10px;
    display: inline-block;
  }
  .logo {
    max-width: 250px;
    height: auto;
  }
  .navbarWrapper {
    display: flex;
    align-items: center;
  }
  @media (max-width: 767px) {
    .headerContent {
      flex-direction: column;
      img {
        max-width: 100%;
        height: 44px;
      }
    }
    .logoWrapper, .navbarWrapper {
      margin-bottom: 1rem;
    }
  }
  .languagePickerWrapper {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1000;
  }
  position: relative;
}


