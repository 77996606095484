.lb-card {
  .card {
    height: 30rem;
    margin-left: auto;
    margin-right: auto;
    .card-text {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: justify;
    }
    .card-img-top {
      height: 200px;
    }
    .bottom {
      margin-bottom: 20px;
      margin-left: 20px;
    }
  }
}
