.main-content {
    .main-banner {  
        width: 100%;
        img {
            width: 100%;
            height: 18rem;
        }
    }
    .body-content {
        padding: 0 65px;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 769px) {
    .main-content {
        .body-content {
            padding: 0 15px;
        }
    }
    
}

@media screen and (min-width: 769px) {
    .body-content {
        display: flex;
        justify-content: center;
        .inner {
            max-width: 1300px;
            padding-left: 65px;
            padding-right: 65px;
        }
    }
    
}