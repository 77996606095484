.general-product {
  .image {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    text-align: center;
    .image-description {
      font-style: italic;
    }
    img {
      max-width: 100%;
    }
  }
  table, tr, td {
    border: 1px solid gray;
  }
}
