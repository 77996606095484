.languagePicker {
  .languageIcon {
    color: #482080;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &.hidden {
      display: none;
    }
  }

  .dropdownWrapper {
    display: none;
    opacity: 0;
    transition: opacity 10s ease;

    &.visible {
      display: block;
      opacity: 1;
      transition: opacity 10s ease;
    }

    .custom-dropdown-item {
      &.active {
        background-color: green !important;
        color: white !important;
      }

      &:active {
        background-color: darkgreen !important;
      }
    }

    button#language-dropdown {
      visibility: hidden;
    }
  }

  // ... other existing styles ...
}
