.lb-navbar {
  .nav-item-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav-item .dropdown {
      margin-left: 8px;
  }
  .bg-light {
    background-color: white !important;
  }
  font-size: 20px;
  font-weight: 500;
  color: #000;
  /* On screens that are 992px wide or less, go from four columns to two columns */
  @media (max-width: 992px) {
    font-size: 16px;
    border-radius: 10px;
    
    .nav-item .dropdown {
      margin-left: 16px;
    }
    div#basic-navbar-nav {
      position: absolute;
      top: 32px;
      background: #dfe9e2;
      width: 21em;
      border-radius: 4px;
      padding: 10px;
      z-index: 1;
    }
    button.navbar-toggler {
      font-size: 10px;
      padding: 4px;
    }
    .basic-navbar-nav {
      padding: 4px;
    }
    .navbar-toggler-icon {
      font-size: 10px;
    }
    
  }
}
