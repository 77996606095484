.footer {
  padding: 10px 30px 0;
  background-color: rgb(64, 64, 64);
  background-image: url(https://bizweb.dktcdn.net/100/360/494/themes/801562/assets/bg-contrinhdathicon.png?1678676184068);
  background-repeat: repeat-x;
  background-position: center;
  color: antiquewhite;
  a {
    text-decoration: none;
    color: antiquewhite;
  }
  a:hover {
    color: adjust-hue($color: #28d539, $degrees: 0);
  }
  h3 {
    text-align: center;
  }
  li {
    text-align: justify;
    padding: 4px 0;
  }
  col {
    margin: 10px;
  }
  .map {
    text-align: center;
    img {
      width: 256px;
      height: auto;
    }
  }
  .nonBulletList {
    list-style-type: none;
    svg {
      color: #8cc540;
      margin-right: 4px;
    }
  }
  .socialIcons {
    display: inline-flex;
    div {
      margin: 0 10px;
    }
  }
}

.copyright {
  color: white;
  text-align: center;
  padding: 10px;
  background-image: linear-gradient(to right, rgb(138 187 42) 15%, rgb(72 32 128) 70%);
}