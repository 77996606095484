.company-member {
    img {
        width: 100%;
    }
    .member {
        text-align: center;
        margin: 2rem;
        .member-info {
            text-align: left;
        }
        img {
            
            max-width: 200px;
            max-height: 200px;
        }
    }
}