.business-fields {
  hr {
    width: 50%;
  }
  img {
    border-radius: 4px;
    width: 100%;
  }
  .intro {
    text-align: center;
    a {
      text-decoration: none;
      text-transform: uppercase;
      color: inherit;
    }
    a:hover {
      color: #8cc540;
    }

    hr {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
    .center-field {
      width: 50%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .article {
    text-align: justify;
    font-size: 1.2rem;
    margin-top: 3rem;
  }
  .partner {
    text-align: center;
    hr {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
      }
  }
}
