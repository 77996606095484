.about-us {
    font-size: 1.2rem;
    img {
        border-radius: 4px;
        width: 100%;
    }
    .business-field-title {
        margin-bottom: 2rem;
    }
    .title {
        text-align: center;
    }
    .right-alignment {
        text-align: right;
    }
    .section {
        margin: 5vw 0;
    }
    .greeting-card {
        font-size: 18px;
        text-align: justify;
        .letter-closing {
            text-align: right;
        }
    }
}