.contactUs {
    h3 {
        color: #663090;
        margin-bottom: 2rem;
    }
    .row {
        margin: 3rem 0;
    }
    .row > div {
        padding: 2rem;
        input, textarea {
            width: 80%;
            border-radius: 4px;
            border: thin solid #663090;
        }
        p {
            font-size: 1.2rem;
        }
        .error {
            color: red;
        }
        svg {
            color: #8cc540;
        }
        .btn-group {
            margin-top: 1rem;
            input {
                width: 100%;
                padding: 0.5rem;
            }
        }
    }
    
}