.news-card {
  margin: 0 10px;
  .lb-card {
    .card {
      width: 100% !important;
      height: 32rem;
    }
  }
}

.news {
  .button-group {
    text-align: center;
    button {
      margin: 4px;
      border-radius: 4px;
      margin-top: 10px;
      border: 1px solid gray
    }
  }
}
