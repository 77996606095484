.mainBanner {
    width: 100%;
    max-height: 500px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  
  .bodyContent {
    padding: 2rem 0;
  
    .inner {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 1rem;
    }
  }
  
  .section {
    margin-bottom: 3rem;
  
    .sectionTitle {
      font-size: 28px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1rem;
    }
  
    hr {
      margin: 1rem 0;
      border: 0;
      border-top: 1px solid #eee;
    }
  }
  
  .product {
    @extend .section;
  
    .productHeader1,
    .productHeader2 {
      h3 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
      }
    }
  
    .productHeader2 {
      display: none;
  
      @media (max-width: 767px) {
        display: block;
        margin-top: 1rem;
      }
    }
  }