.slide-effect {
  object-fit: cover;
  transition: all 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.fade {
  opacity: 0;
  animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
}

.slide-left {
  transform: translateX(100%) scale(0.8);
  opacity: 0;
  animation: slide-in-left 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.slide-right {
  transform: translateX(-100%) scale(0.8);
  opacity: 0;
  animation: slide-in-right 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.slide-up {
  transform: translateY(100%) scale(0.8);
  opacity: 0;
  animation: slide-in-up 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.slide-down {
  transform: translateY(-100%) scale(0.8);
  opacity: 0;
  animation: slide-in-down 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.fractal {
  opacity: 0;
  clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  animation: fractal-expand 2s cubic-bezier(0.26, 0.53, 0.74, 1.48) forwards;
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(100%) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(-100%) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}

@keyframes slide-in-up {
  0% {
    transform: translateY(100%) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes slide-in-down {
  0% {
    transform: translateY(-100%) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes fractal-expand {
  0% {
    opacity: 0;
    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  }
  10% {
    opacity: 1;
    clip-path: polygon(25% 25%, 75% 25%, 75% 75%, 25% 75%);
  }
  25% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  40% {
    clip-path: polygon(10% 10%, 90% 10%, 90% 90%, 10% 90%);
  }
  60% {
    clip-path: polygon(20% 20%, 80% 20%, 80% 80%, 20% 80%);
  }
  80% {
    clip-path: polygon(5% 5%, 95% 5%, 95% 95%, 5% 95%);
  }
  100% {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
